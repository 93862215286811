// Menu.js
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Menu = ({ menus }) => {
    const [isActive, setIsActive] = useState(false);

    console.log(menus)
    const handleToggle = () => {
        setIsActive(!isActive);
    };
    // Check if menus is an array and map over it
    if (!Array.isArray(menus) || menus.length === 0) {
        return null;
    }
    const parentMenus = menus.filter(menu => menu.ChildOf === 0);
    return (

        <>

            <header>
                <div className="responsive">
                    <div id="MENU" className={isActive ? 'active' : ''} onClick={handleToggle}>
                        <div className={`bar top ${isActive ? 'active' : ''}`} />
                        <div className={`bar bottom ${isActive ? 'active' : ''}`} />
                    </div>

                    <div id="NAV" className={`nav ${isActive ? 'active' : ''}`}>

                        <ul>
                            {parentMenus.map((parentMenu, index) => (
                                <li key={index}>
                                    <Link to={`/${parentMenu.Pageurl}`}>
                                        {parentMenu.Title}
                                    </Link>
                                    {/* Render submenus for this parent menu */}
                                    {/* <ul>
                                        {menus
                                            .filter(submenu => submenu.ChildOf === parentMenu.Id)
                                            .map(submenu => (
                                                <li key={submenu.Id}>
                                                    <Link to={`/${submenu.Pageurl}`}>
                                                        {submenu.Title}
                                                    </Link>
                                                </li>
                                            ))}
                                    </ul> */}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </header>

        </>
    );
};

export default Menu;
