import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPageContent } from '../sevices/api';
import DOMPurify from 'dompurify';

const DynamicContent = ({ schoolId, orgId, token, setIsDynamicPage, menus }) => {
    const { pageUrl } = useParams();
    const [pageData, setPageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPageContent = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await getPageContent(schoolId, orgId, pageUrl, token);
                setPageData(data); // Set the pageData to the fetched data
                setIsDynamicPage(true); // Mark this as a dynamic page
            } catch (error) {
                console.error('Error fetching page content:', error);
                setError('Failed to load page content.');
            } finally {
                setLoading(false);
            }
        };

        fetchPageContent();
    }, [pageUrl, schoolId, orgId, token, setIsDynamicPage]);

    // Immediately return placeholder content to improve perceived loading speed
    if (loading) {
        return (
           <p className='text-center'>Loading....</p>
        );
    }

    if (error) return <div><p>Website in Maintenance</p></div>;

    // Find the parent menu based on pageUrl
    const parentMenu = menus.find(menu => menu.Pageurl === pageUrl);

    // Get the submenus of the parent
    const subMenus = parentMenu ? menus.filter(menu => menu.ChildOf === parentMenu.Id) : [];

    if (pageData && pageData.length > 0) {
        const contentData = pageData[0]; // Access the first object in the array
        const menuContent = contentData.MenuContent;

        if (menuContent) {
            try {
                // Correct Base64 format for decoding
                const correctedBase64 = menuContent.replace(/-/g, '+').replace(/_/g, '/');
                const padding = correctedBase64.length % 4;
                if (padding) {
                    correctedBase64 += '='.repeat(4 - padding);
                }
                const decodedContent = atob(correctedBase64);

                return (
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-9'>
                                <h1>{contentData.Title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: decodedContent }} />
                            </div>

                            <div className="col-lg-3 col-md-3 col-xs-12 mt-5">
                                <div className="MenuLinkbtn_Div">
                                    <ul id="idSubmenus">
                                        {subMenus.length > 0 ? (
                                            subMenus.map((submenu) => (
                                                <li key={submenu.Id} className="list-unstyled">
                                                    <a className="text-decoration-none" href={`/${submenu.Pageurl}`}>
                                                        {submenu.Title}
                                                    </a>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="list-unstyled">No submenus available</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } catch (decodeError) {
                console.error('Error decoding MenuContent:', decodeError);
                return <div>Error decoding content.</div>;
            }
        } else {
            return <div>No MenuContent available.</div>;
        }
    }

    return <div>No content available.</div>;
};

export default DynamicContent;
