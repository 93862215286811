import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = ({ token, schoolId, orgId }) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [mobileNo, setMobileNo] = useState('');
	const [subject, setSubject] = useState('');
	const [fMessage, setFMessage] = useState('');
	const [responseMessage, setResponseMessage] = useState('');

	const baseURL = process.env.REACT_APP_API_URL;
	const handleSubmit = async (e) => {
		e.preventDefault();
		const method = `WAdmissionEnquire?SchoolId=${schoolId}&OrgId=${orgId}&Name=${name}&EmailId=${email}&Fathername=&Class=&branch=&Address=${subject}&Mobile=${mobileNo}&Message=${fMessage}`;

		try {
			const response = await axios.get(`${baseURL}SiteService.asmx/${method}`, {
				headers: {
					'HeaderToken': token // Only the headers here
				}
			});

			if (response.data) {
				setResponseMessage('Feedback submitted successfully!');
			} else {
				setResponseMessage('Failed to submit feedback.');
			}
		} catch (error) {
			console.error(error);
			setResponseMessage('Error submitting feedback.');
		}
	};

	return (
		<div className="contact-form">
			<h4 className="text-center">Take the Next Step</h4>
			<h4 className="column-title text-center">Fill the Form <span className="shape-bg">Now!</span></h4>
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="iconInput">
							<label htmlFor="name">Full Name<span className="px-1">*</span></label>
							<input
								type="text"
								name="name"
								id="name"
								className="required"
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="iconInput">
							<label htmlFor="phone">Contact No.<span className="px-1">*</span></label>
							<input
								className="form-control"
								type="text"
								maxLength={10}
								id="phone"
								name="phone"
								value={mobileNo}
								onChange={(e) => setMobileNo(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="iconInput">
							<label htmlFor="subject">Subject<span className="px-1">*</span></label>
							<input
								type="text"
								name="subject"
								id="subject"
								className="required"
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-lg-6 col-md-12 col-sm-12 col-12">
						<div className="iconInput">
							<label htmlFor="email">Email<span className="px-1">*</span></label>
							<input
								type="email"
								name="email"
								id="email"
								className="required"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="iconInput">
							<label htmlFor="message">Message<span className="px-1">*</span></label>
							<textarea
								className="form-control"
								placeholder="Leave a comment here"
								id="message"
								name="message"
								value={fMessage}
								onChange={(e) => setFMessage(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-lg-12 mt-3 mb-3 text-center text-sm-start">
						<button
							type="submit"
							id="contactbtn"
							className="btn grap-btn to_top w-auto"
						>
							Send Message
						</button>
					</div>
				</div>
			</form>
			{responseMessage && <p className="response-message">{responseMessage}</p>}
		</div>
	);
};

export default ContactForm;
