
import React from 'react';
import '../App.css';
import slider1 from '../Assets/Images/1.jpg'
import slider2 from '../Assets/Images/3.jpeg'
import slider3 from '../Assets/Images/2.jpg'
import Librimage from '../Assets/Images/library.jpg'
import Transimage from '../Assets/Images/transport.jpeg'
import ComputLab from '../Assets/Images/computerlab.png'
import mentorimage from '../Assets/Images/mentor.jpeg'
import { Link } from 'react-router-dom';
const Home = ({ slider, forthcoming, WelcomeMessage }) => {
    // console.log(typeof slider, slider);
    if (!Array.isArray(slider)) {
        return; // Handle non-array case

    }

    return (
        <>
            <div id="Home_slider" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    {slider.map((sliderItem, index) => {
                        return (
                            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                <img src={sliderItem.imageurl} alt={`Slider Image ${index}`} className="d-block w-100" />
                                <div className="carousel-caption d-none d-md-block">
                                    <h5>{sliderItem.Caption || "No caption available"}</h5>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#Home_slider" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#Home_slider" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>


            <div className="campus-information-area pt-70 pb-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div
                            className="col-lg-6"
                            data-aos="fade-right"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration={1300}
                            data-aos-once="true"
                        >
                            <div className="campus-content pr-20">
                                <div className="campus-title">
                                    <h2>Welcome to D. P. S. College of Education</h2>
                                    {WelcomeMessage ? (
                                        <p>{WelcomeMessage}</p> // Render as a single paragraph
                                    ) : (
                                        <p>Welcome message is loading...</p>
                                    )}
                                </div>
                                <Link to="/AboutUs">Read More</Link>
                            </div>
                        </div>
                        <div
                            className="col-lg-6"
                            data-aos="fade-up"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration={1300}
                            data-aos-once="true"
                        >
                            <div className="campus-image pl-20">
                                <img
                                    className="w-100 img-fluid"
                                    src={slider3}
                                    alt="Image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="event-area pt-70 pb-50">
                <div className="container text-center">
                    <div className="row g-3">
                        <div className="col-lg-6">
                            <div className="recent-events">
                                <nav>
                                    <div className="nav nav-tabs">
                                        <button className="nav-link active">Announcements</button>
                                    </div>
                                </nav>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active">
                                        <div className="single-podcasts-card style2">
                                            <div className="podcast-content">
                                                <marquee
                                                    scrollamount={5}
                                                    id="event"
                                                    onmouseover="this.stop()"
                                                    onmouseout="this.start()"
                                                    behavior="scroll"
                                                    direction="up"
                                                >

                                                    <div className="mt-3  main_div_box">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-12 m-auto">
                                                                <div className="txt_cnt">
                                                                    <p>
                                                                        {
                                                                            forthcoming && forthcoming.length > 0 ?
                                                                                (
                                                                                    forthcoming.map((slider, index) => (
                                                                                        <Link key={index} className="fa-solid fa-bullhorn pe-2" to="/Admissionform">
                                                                                            <i className="fa-solid fa-bullhorn pe-2" />
                                                                                            {slider.Title}
                                                                                        </Link>
                                                                                    ))
                                                                                ) :

                                                                                (<p>Data is loading </p>)

                                                                        }

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </marquee>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-evt">
                            <h2>OUR GALLERY</h2>
                            <div
                                id="carouselSlidesOnly"
                                className="carousel slide"
                                data-bs-ride="carousel"
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img
                                            src={slider1}
                                            className="d-block w-100"
                                            alt="First slide"
                                        />

                                    </div>
                                    <div className="carousel-item">
                                        <img
                                            src={slider3}
                                            className="d-block w-100"
                                            alt="Second slide"
                                        />
                                    </div>
                                </div>

                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselSlidesOnly" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselSlidesOnly" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mentormessage'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='mentor_images text-center'>
                                <img src={mentorimage} />
                            </div>
                            <div>
                                <h4 className='text-center pt-4 pb-2'>Chairman Message</h4>
                                <p>We cannot always build the future for our youth, but we can build our youth for the future. It is often said the world's future rests with our children and youth. The success of any nation depends on the youth who further depends upon the quality of instruction that can only be provided through quality teacher education. Since this is a teacher training institution, we are engaged in making our students not only competent teachers but also good human being. We believe in 'fueling the quest for knowledge'. In doing so, our commitments pave the way for the individual professional success which culminates in benefiting the society. Ability is what you're capable of doing, Motivation determines what you do, and Attitude determines how well you do it. So I welcome you all and look forward towards helping you develop as an asset of the nation and prepare you for an enriching career in education.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="what-look-bg gradient-bg pt-30 pb-20 pt-md-95 pb-md-80">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title text-center mb-55">
                                <h2 className="designheading">What We Aim To Accomplish</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-85">
                        <div className="col-xl-6 col-lg-6 col-md-6 mt-3">
                            <div
                                className="what-box text-center mb-35 wow fadeInUp2  animated"
                                data-wow-delay=".3s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.3s",
                                    animationName: "fadeInUp2"
                                }}
                            >
                                <div className="what-box__icon mb-30">
                                    <img src="https://dpskarnal.com/assets/img/icon/vision.gif" alt="" />
                                </div>
                                <h3>OUR VISION</h3>
                                <p>
                                    "Delhi Public School Karnal provides high quality comprehensive
                                    education with a firm moral base that addresses the academic needs
                                    of the students within a sheltering and nurturing environment."
                                </p>

                                <Link className="theme_btn border_btn">Read More</Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 mt-3">
                            <div
                                className="what-box text-center mb-35 wow fadeInUp2  animated"
                                data-wow-delay=".3s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.3s",
                                    animationName: "fadeInUp2"
                                }}
                            >
                                <div className="what-box__icon mb-30">
                                    <img
                                        src="https://dpskarnal.com/assets/img/icon/Rocket-animate%20(1).gif"
                                        style={{ width: "18%" }}
                                        alt=""
                                    />
                                </div>
                                <h3>OUR MISSION</h3>
                                <p>
                                    "Our school is a community-learning centre that develops individual
                                    pathways for students in order to encourage and support them to
                                    achieve personal standards of excellence in a caring environment."
                                </p>

                                <Link className="theme_btn border_btn">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="academic-area pt-70 pb-50">
                <div className="container">
                    <h1 className='text-center'>OUR FACILITIES</h1>
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="row">
                                <div
                                    className="col-lg-4 col-md-4 aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-duration={200}
                                    data-aos-delay={50}
                                    data-aos-once="true"
                                >
                                    <div className="single-academics-card">
                                        <div className="academic-top-content">
                                            <div className="icon-bg">
                                                <img src={ComputLab} />
                                            </div>
                                            <a href="#">
                                                <h3>
                                                    Computer Lab
                                                </h3>
                                            </a>
                                        </div>
                                        <div className="readmorelink">
                                            {/* <a href="#" className="read-more-btn">
                                                    Visit Here
                                                </a> */}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-4 col-md-4 aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-duration={200}
                                    data-aos-delay={50}
                                    data-aos-once="true"
                                >
                                    <div
                                        className="single-academics-card"
                                    >
                                        <div className="academic-top-content">
                                            <div className="icon-bg">
                                                <img src={Librimage} />
                                            </div>
                                            <a href="#" className="read-more-btn">
                                                <h3>Library</h3>

                                            </a>
                                        </div>
                                        <div className="readmorelink">
                                            <div className="readmorelink">
                                                {/* <a href="#" className="read-more-btn">
                                                    Visit Here
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-lg-4 col-md-4 aos-init aos-animate"
                                    data-aos="fade-up"
                                    data-aos-duration={200}
                                    data-aos-delay={50}
                                    data-aos-once="true"
                                >
                                    <div className="single-academics-card">
                                        <div className="academic-top-content">
                                            <div className="icon-bg">
                                                <img src={Transimage} />
                                            </div>
                                            <a href="#">
                                                <h3>Transport</h3>
                                            </a>
                                        </div>
                                        <div className="readmorelink">
                                            {/* <a href="#" className="read-more-btn">
                                                    Visit Here
                                                </a> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Home;

