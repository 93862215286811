import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/home';
import Header from './components/Header';
import Footer from './components/Footer';
import DynamicPage from './sevices/DynamicPage';
import { fetchSchoolInfo, fetchSlider, fetchForthComingEve, Welcomemessage } from './sevices/api';
import { useParams } from 'react-router-dom';
import { getPageId, getAllMenus } from './sevices/api'; // Adjust the path as needed
import Menu from '../src/components/Navbar'; // Import the Menu component
import DynamicContent from './components/DynamicContent '



function App() {
    const [orgId, setOrgId] = useState(null);
    const [schoolId, setSchoolId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const [slider, setSlider] = useState([]);
    const [forthcoming, setForthcoming] = useState('');
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const { pageUrl } = useParams();
    const [content, setContent] = useState('');
    const [menus, setMenus] = useState([]);
    const [pageId, setPageId] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [isDynamicPage, setIsDynamicPage] = useState(false); // New state to track dynamic page view

    // Fetch Org and School ID
    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const data = await fetchSchoolInfo();
                if (data && data.length > 0) {
                    setOrgId(data[0].OrgId);
                    setSchoolId(data[0].SchoolId);
                    setToken(data[0].HeaderToken);
                } else {
                    setError('No data found');
                }
            } catch (err) {
                console.error(err);
                setError('Error fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchInfo();
    }, []);

    // Fetch Slider Data
    useEffect(() => {
        const getSliderData = async () => {
            if (schoolId && orgId) {
                try {
                    const sliderData = await fetchSlider('1', schoolId, orgId, token);
                    console.log('Slider data',sliderData)
                    setSlider(sliderData);
                } catch (error) {
                    console.error('Error fetching slider:', error);
                }
            }
        };

        getSliderData();
    }, [schoolId, orgId, token]);

    // // Fetch Forthcoming Events
    useEffect(() => {
        const getForthcomingEvents = async () => {
            if (schoolId && orgId) {
                try {
                    const forthcoming = await fetchForthComingEve('1', schoolId, orgId, token);
                    setForthcoming(forthcoming);
                } catch (error) {
                    console.error('Error fetching forthcoming events:', error);
                }
            }
        };

        getForthcomingEvents();
    }, [schoolId, orgId, token]);

    // Welcome Message
    useEffect(() => {
        const getWelcomeMessage = async () => {
            if (schoolId && orgId) {
                console.log('Fetching welcome message...');
                try {
                    const welcomeMess = await Welcomemessage('1', schoolId, orgId, token);
    
                    // Assuming welcomeMess is an array with objects that contain Message property
                    if (welcomeMess && welcomeMess.length > 0) {
                        // Get the first item’s Message field
                        const messageContent = welcomeMess[0].Message;
    
                        // Set the message as-is if you want HTML, or strip HTML tags if you want plain text
                        const plainTextMessage = messageContent.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    
                        console.log('Welcome message text:', plainTextMessage);
                        setWelcomeMessage(plainTextMessage);
                    }
                } catch (error) {
                    console.error('Error fetching welcome message:', error);
                }
            } else {
                console.log('Missing schoolId or orgId');
            }
        };
    
        getWelcomeMessage();
    }, [schoolId, orgId, token]);
    
    // Welcome Message


    // // Menu Bar

    useEffect(() => {
        const fetchMenuData = async () => {
            if (schoolId && orgId && token) {
                try {
                    const menuData = await getAllMenus(schoolId, orgId, token);
                    console.log(menuData)
                    setMenus(menuData); // Set the menu data
                } catch (error) {
                    console.error('Error fetching main menu:', error);
                }
            }
        };

        fetchMenuData();
    }, [schoolId, orgId, token]);
  //  Menu Bar


    // Render loading or error state
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Router>
        <div className="App">
            <Header />
            <Menu menus={menus} />
            <Routes>
                <Route path="/" element={<HomePage
                    WelcomeMessage={welcomeMessage}
                    forthcoming={forthcoming}
                    slider={slider}
                    schoolId={schoolId}
                    orgId={orgId}
                />} />
                <Route path="/:pageUrl" element={<DynamicContent 
                    menus={menus}
                    schoolId={schoolId}
                    orgId={orgId}
                    token={token}
                    setIsDynamicPage={setIsDynamicPage}
                />} />
            </Routes>
            <Footer token={token} schoolId={schoolId} orgId={orgId} />
            <div className="bottom text-center">
                <p> © 2024 D.P.S College of Engineering, All Rights Reserved Powered By : <a target='_blank' href="https://campuspro.in/">CampusPro</a> </p>
            </div>
        </div>
    </Router>
    );
}

export default App;
