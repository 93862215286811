
  import React from 'react';
  import ContactForm from './ContactForm';
  const Footer = ({ token,schoolId,orgId})=>  {
	return (
	    <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12 my-auto">
              <div className="col-span-5 lg:col-span-6 col-span-12">
                <h4 className="column-title">Get In Touch <span className="shape-bg">Today</span></h4>
                <p>Reach out to us for any assistance you need. We're here to help!</p>
                <ul className="list-item space-y-6 pt-8">
                  <li className="flex">
                    <div className="flex-none mr-6">
                      {/* <img src="https://bestwpware.com/react-templates/edumim/static/media/mail.17a0df91a8dcae2b856bf91e18c6f978.svg" alt="" /> */}
                    </div>
                    <div className="flex-1">
                      <h4 className="lg:text-xl text-lg mb-1">Email-Us :</h4>
                      <a href="#"><p>#</p></a>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-none mr-6">
                      {/* <img src="https://bestwpware.com/react-templates/edumim/static/media/call.27a889d82f15a9cda43740f8566ef01b.svg" alt="" /> */}
                    </div>
                    <div className="flex-1">
                      <h4 className="lg:text-xl text-lg mb-1">Call Us:</h4>
                      <a href="tel:9354413380"><p>9354413380</p></a>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-none mr-6">
                      {/* <img src="https://bestwpware.com/react-templates/edumim/static/media/map.d48561289cc103d8ccf3033f64c7ff15.svg" alt="" /> */}
                    </div>
                    <div className="flex-1">
                      <h4 className="lg:text-xl text-lg mb-1">Office :</h4>
                      <p>Manesar Gurugram, Haryana, India</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
            <ContactForm token={token} schoolId={schoolId}  orgId={orgId}  />
            </div>
          </div>
        </div>
      </footer>
	);
  }
  
  export default Footer;
  