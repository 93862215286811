import React from 'react';
import Navbar from './Navbar'
import Logo from '../Assets/Images/dpslogo.png';

const Header = () => (
	<header>
		<div className="upperHeader">
			<div className="container">
				<div className="row">
					<div className="col-md-12 col-lg-8 col-xl-7 col-sm-12 col-12">
						<div className="headdd row">
							{/* Contact numbers */}
							<div className="col-md-5 col-lg-5 col-sm-4 col-6 hide my-auto">
								<div className="d-flex pp justify-content-center">
									<i className="bi bi-telephone-fill" style={{ color: "white", fontSize: "16px", marginRight: 3 }} />
									<a href="tel:#">
										<p style={{ fontFamily: "helvetica" }} className="mx-1 adm">+91#</p>
									</a>
								</div>
							</div>
							<div className="col-md-4 col-lg-4 col-sm-4 col-6 hide2 justify-content-end hide my-auto hidatla">
								<div className="d-flex pp text-center" style={{ whiteSpace: "nowrap" }}>
									<i className="bi bi-telephone-fill" style={{ color: "white", fontSize: "16px", marginRight: 3 }} />
									<a href="tel:+91#">
										<p style={{ fontFamily: "helvetica" }} className="mx-1 adm">+91#</p>
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* Social media icons */}
					<div className="col-md-12 col-lg-4 col-xl-5 col-sm-12 col-12 text-sm-end text-center mx-auto my-auto iconss" style={{ marginTop: 11 }}>
						<div className="col-12 text-md-center text-sm-center text-lg-end">
							<a href="tel:"><i className="bi bi-telephone-fill" /></a>
							<a href=""><i className="bi bi-envelope-fill" /></a>
							<a target="_blank" href="#"><i className="bi bi-whatsapp" /></a>
							<a target="_blank" href="#"><i className="bi bi-facebook" /></a>
							<a target="_blank" href="#"><i className="bi bi-instagram" /></a>
							<a target="_blank" href="#"><i className="bi bi-youtube" /></a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="container-fluid">
			<div className='middleheader'>
				<div className="row mb-0 d-flex align-items-center">
					<div className="col-lg-12 col-12 text-center d-flex justify-content-center pt-3 pb-3">
						<img src={Logo}/>
						{/* <h1 className='my-auto'>D.P.S COLLEGE OF EDUCATION</h1> */}
					</div>
				</div>
			</div>
		</div>

		<Navbar/>
		
	</header>
);

export default Header;
