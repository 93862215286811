import axios from 'axios';

const isInteger = (value) => {
    return /^\d+$/.test(value);
};

const baseURL = process.env.REACT_APP_API_URL;

console.log(baseURL); // Log baseURL for debugging purposes

// Fetch School Information
const fetchSchoolInfo = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_SCHOOL_INFO_URL);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data; // Assuming data is an array and contains OrgId and SchoolId
    } catch (error) {
        console.error('Error fetching school info:', error);
        throw error; // Rethrow to handle in the component
    }
};

// Get Page ID by URL
const getPageId = async (schoolId, orgId, pageUrl, token) => {
    let pageId = "0"; // Default value

    if (!isInteger(pageUrl)) {
        try {
            const method = `WGetPageIdByUrl?SchoolId=${schoolId}&OrgId=${orgId}&PageUrl=${encodeURIComponent(pageUrl)}`;
            const response = await axios.get(`${baseURL}SiteService.asmx/${method}`, {
                headers: {
                    'HeaderToken': token // Include your token in the headers
                }
            });

            if (response.data && response.data !== "Failed") {
                pageId = response.data; // Set the Page ID from the response
            }
        } catch (error) {
            console.error('Error fetching Page ID:', error);
            throw error; // Rethrow the error to handle it in the calling component
        }
    } else {
        pageId = pageUrl; // If it's an integer, set PageId directly
    }

    return pageId;
};

// Get All Menus
const getAllMenus = async (schoolId, orgId, token) => {
    try {
        const method = `WGetAllMenus?SchoolId=${schoolId}&OrgId=${orgId}&ChidId=0`;
        const response = await axios.get(`${baseURL}SiteService.asmx/${method}`, {
            headers: {
                'HeaderToken': token
            }
        });

        return response.data; // Adjust based on your API's response structure
    } catch (error) {
        console.error('Error fetching all menus:', error);
        throw error; // Handle or rethrow the error as needed
    }
};

// Get Page Content
const getPageContent = async (schoolId, orgId, pageUrl, token) => {
    try {
        const pageId = await getPageId(schoolId, orgId, pageUrl, token);
        const method = `WGetContent?SchoolId=${schoolId}&OrgId=${orgId}&Id=${pageId}`;
        const response = await axios.get(`${baseURL}SiteService.asmx/${method}`, {
            headers: {
                'HeaderToken': token // Include your token in the headers
            }
        });

        if (response.data) {
            return response.data; // Adjust based on your API response structure
        } else {
            throw new Error('No content found for this page.');
        }
    } catch (error) {
        console.error('Error fetching page content:', error);
        throw error; // Rethrow the error to handle it in the calling component
    }
};

// Fetch Slider
const fetchSlider = async (PID, schoolId, orgId, token) => {
    try {
        const method = `WGetflashImage?SchoolId=${schoolId}&OrgId=${orgId}&Id=${PID}`;
        const response = await axios.get(`${baseURL}SiteService.asmx/${method}`, {
            headers: {
                'HeaderToken': token
            }
        });
        console.log('Response Data:', response.data); // Log the data here
        return response.data;
    } catch (error) {
        console.error('Error fetching slider data:', error);
        throw error; // Handle or rethrow the error as needed
    }
};

// Fetch Forthcoming Events
const fetchForthComingEve = async (PID, schoolId, orgId, token) => {
    try {
        const method = `WForthCommingEvents?SchoolId=${schoolId}&OrgId=${orgId}&Id=${PID}`;
        const response = await axios.get(`${baseURL}SiteService.asmx/${method}`, {
            headers: {
                'HeaderToken': token // Only the headers here
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching forthcoming events:', error);
        throw error; // Handle or rethrow the error as needed
    }
};

// Fetch Welcome Message
const Welcomemessage = async (PID, schoolId, orgId, token) => {
    try {
        const method = `WWelComemsg?SchoolId=${schoolId}&OrgId=${orgId}&Id=${PID}`;
        const response = await axios.get(`${baseURL}SiteService.asmx/${method}`, {
            headers: {
                'HeaderToken': token
            }
        });

        console.log('Data is this:', response.data); // Log the response for debugging

        // Decode messages in each row
        const dataTable = response.data;
        dataTable.forEach(row => {
            if (row.Message) {
                const decodedMessage = atob(row.Message); // Decode Base64 string
                row.Message = decodedMessage;
            }
        });

        return dataTable; 
    } catch (error) {
        console.error('Error fetching welcome message:', error);
        throw error; 
    }
};


export { getAllMenus, getPageId, getPageContent, Welcomemessage, fetchForthComingEve, fetchSlider, fetchSchoolInfo };
